@font-face {
  font-family: 'safebuy';
  src:  url('fonts/safebuy.eot?8qoj7r');
  src:  url('fonts/safebuy.eot?8qoj7r#iefix') format('embedded-opentype'),
    url('fonts/safebuy.ttf?8qoj7r') format('truetype'),
    url('fonts/safebuy.woff?8qoj7r') format('woff'),
    url('fonts/safebuy.svg?8qoj7r#safebuy') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="sb-"], [class*=" sb-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'safebuy' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sb-clock:before {
  content: "\e900";
}
.sb-mail:before {
  content: "\e901";
}
.sb-call:before {
  content: "\e902";
}
.sb-profile:before {
  content: "\e903";
}
.sb-mobile-payment:before {
  content: "\e904";
}
.sb-calendar:before {
  content: "\e905";
}
.sb-calendar1:before {
  content: "\e906";
}
.sb-checklist:before {
  content: "\e907";
}
