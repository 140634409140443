.FooterContainer {
	width: 100%;
	float: left;
	padding: 35px 0;
	font-size: 13px;
	color: #fff;
	background-color: #292929;
}
.FooterContainer a,
.FooterContainer a:hover,
.FooterContainer a:active,
.FooterContainer a:focus{
color: #fff;
    text-decoration: none;
}
.FooterImage{
	display: flex;
	justify-content: center;
}

.footer-terms-conditions{
	text-decoration: none;
	color: #fff;
}