.HomePageContainer {
    width: 100%;
    float: left;
}

.BannerSection {
    width: 100%;
    float: left;
    background-color: #000;
    min-height: 500px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
}

.BannerTextTitle {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #fff;
    line-height: 45px;
}

.BannerTextTitle span {
    width: 100%;
    float: left;
}

.BannerTextContainer {
    width: 100%;
    float: left;
    margin-top: 40px;
    position: relative;
        z-index: 1;
}

.BannerTextDesc {
    width: 100%;
    float: left;
    padding: 15px 0;
    padding-top: 10px;
    font-size: 13px;
    color: #fff;
    line-height: 22px;
}

.BannerInputContainer {
    width: 100%;
    float: left;
}

.BannerInputContainer .ant-form-item {
    margin-bottom: 18px;
}

.BannerInputContainer .ant-form-item:last-child {
    margin-bottom: 0;
}

.BannerInputContainer .ant-input {
    padding: 8px 15px;
    outline: none;
    border-color: #fff;
    border-radius: 25px;
    font-size: 13px;
}

.BannerInputContainer .ant-btn,
.BannerInputContainer .ant-btn:active,
.BannerInputContainer .ant-btn:focus,
.BannerInputContainer .ant-btn:hover {
    background-color: #f36a2b;
    border-color: #f36a2b;
    color: #fff;
    font-size: 13px;
    border-radius: 25px;
    padding: 6px 15px;
    height: auto;
}

.StepsSection {
    width: 100%;
    float: left;
    position: relative;
    top: -32px;
}

.ant-steps.StepsItems .ant-steps-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ant-steps.StepsItems .ant-steps-item-container .ant-steps-item-icon {
    margin-right: 0;
    width: 60px !important;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    position: relative;
    z-index: 3;
}

.ant-steps.StepsItems .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
    width: auto;
    height: auto;
    left: 0;
}

.ant-steps.StepsItems .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .anticon {
    float: left;
}

.ant-steps.StepsItems .ant-steps-item-container .ant-steps-item-title {
    padding-right: 0;
    font-size: 13px;
    color: #010101 !important;
}

.ant-steps.StepsItems .ant-steps-item {
    position: relative;
    flex: 1;
}

.ant-steps.StepsItems .ant-steps-item:after {
    position: absolute;
    top: 32px;
    left: -20px;
    display: block;
    width: 9999px;
    height: 1px;
    background: #bfbfbf;
    content: '';
    margin-left: 130px;
    z-index: 2;
}

.ant-steps.StepsItems {
    overflow: hidden;
    display: flex;
}

.ant-steps.StepsItems .ant-steps-item {
    overflow: visible;
}

.ant-steps.StepsItems .ant-steps-item .ant-steps-item-title::after {
    display: none;
}

.ant-steps.StepsItems .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    color: #010101;
}

.ant-steps.StepsItems:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    background-color: #fff;
}

.ant-steps.StepsItems .ant-steps-item-active.ant-steps-item .ant-steps-item-icon,
.ant-steps.StepsItems .ant-steps-item-finish.ant-steps-item .ant-steps-item-icon {
    color: #fff;
    background-color: #f36a2b;
}

.ant-steps.StepsItems .ant-steps-item-active.ant-steps-item .ant-steps-item-icon .ant-steps-icon,
.ant-steps.StepsItems .ant-steps-item-finish.ant-steps-item .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
}

.ant-steps.StepsItems .ant-steps-item-finish.ant-steps-item:after {
    background-color: #f36a2b;
}

.ant-steps.StepsItems .ant-steps-item:last-child:after {
    background-color: #fff !important;
}

.StepsSection .steps-action {
    width: 100%;
    float: left;
    display: flex;
    justify-content: flex-start;
    padding: 15px 0;
    border-top: 1px solid #e6e2eb;
    flex-direction: row-reverse;
}

.StepsSection .steps-action .ant-btn {
    background-color: #f36a2b;
    border-color: #f36a2b;
    color: #fff;
    font-size: 13px;
    border-radius: 25px;
    padding: 6px 15px;
    height: auto;
}

.StepsSection .steps-action .ant-btn.backbutton {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #010101;
}

.StepsSection .steps-action .ant-btn {
    padding: 6px 25px;
}
.ant-steps.StepsItems .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
    font-size: 30px;
    line-height: 0;
}
/* First Step Style Start */
.InspectionStepsContainer {
    width: 100%;
    float: left;
}

.InspectionStepTitle {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #010101;
    padding: 50px 0;
    text-align: center
}

.PricingCardSection {
    width: 100%;
    float: left;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e2eb;
}

.PricingCardSection .PricingCard {
    width: 100%;
    float: left;
    position: relative;
    margin: 15px 0;
    white-space: normal;
    word-break: break-all;
}
.PricingCardSection .ant-checkbox-group {
    width: 100%;
    float: left;
}
.PricingCardSection .ant-radio-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    min-height: 170px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
}

.PricingCardSection .ant-radio-wrapper .ant-radio {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.PricingCardSection .ant-radio-wrapper .ant-radio-inner {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
}

.PricingCardSection .ant-radio-wrapper .ant-radio-inner:after {
    display: none;
}

.PricingCardSection .ant-radio-wrapper:hover .ant-radio,
.PricingCardSection .ant-radio:hover .ant-radio-inner,
.PricingCardSection .ant-radio-input:focus+.ant-radio-inner {
    border-color: #f36a2b;
    border-width: 2px;
}

.PricingCardSection .ant-radio-checked .ant-radio-inner {
    border-color: #f36a2b;
    border-width: 2px;
}

.PricingCardSection .ant-radio-checked::after {
    display: none;
}

.PricingCardSection .ant-radio-group {
    width: 100%;
    float: left;
}

.PricingCardInfo {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #101010;
    font-size: 14px;
    word-break: break-word;
    white-space: normal;
    text-align: center;
}

.PricingCardInfo .PricingCardAmount {
    font-size: 40px;
    color: #f36a2b;
    color: #000;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 10px;
    transition: all ease .3s;
}

.PricingCard .ant-radio-wrapper.ant-radio-wrapper-checked .PricingCardInfo .PricingCardAmount {
    color: #f36a2b;
    transition: all ease .3s;
}

.PricingCardInfo .PricingCardPlan {
    font-size: 16px;
    font-weight: 600;
}

.PricingAddOnSection {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.PricingAddOnTitle {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 30px;
    color: #000;
    font-weight: 600;
}

.PricingAddOnSubTitle {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 20px;
    color: #f36a2b;
    font-weight: 600;
}

.PricingAddOnDesc {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    padding: 10px 0;
}

.PricingAddOnSection .PricingCardSection {
    padding: 30px 0;
    border: none;
}

.PricingAddOnSection .PricingCardSection .ant-radio-wrapper {
    min-height: 130px;
}

.CouponCodeInputSection {
    width: 100%;
    float: left;
    padding: 20px 0;
    margin-bottom: 15px;
}

.CouponCodeInputSection .ant-input-group-addon {
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    border-color: transparent;
    background-color: transparent;
    position: relative;
    z-index: 2;
}

.CouponCodeInputSection .ant-input-group-addon .ant-btn {
    background-color: #f36a2b;
    border-color: #f36a2b;
    color: #fff;
    font-size: 13px;
    border-radius: 25px;
    padding: 5px 35px;
    height: auto;
}

.CouponCodeInputSection .ant-input-group .ant-input {
    float: left;
    width: 108%;
    margin-bottom: 0;
    text-align: inherit;
}

.CouponCodeInputSection .ant-input-group .ant-input {
    background-color: #f5f5f7;
    border-color: #f5f5f7;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.CouponCodeTotal {
    width: 100%;
    float: left;
}

.CouponCodeTotalSection {
    color: #000000;
    font-size: 16px;
    /* width: 100%;
    float: left; */
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    padding: 5px 15px;
    border-radius: 5px;
    display: inline-block;
}

.CouponCodeTotalAmount {
    color: #f36a2b;
    font-size: 20px;
    font-weight: 600;
    margin-left: 4px;
}

.PricingCardSection .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    min-height: 170px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
}

.PricingCard .ant-checkbox.ant-checkbox-checked+span .PricingCardInfo .PricingCardAmount {
    color: #f36a2b;
    transition: all ease .3s;
}

.PricingAddOnSection .PricingCard .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b3d;
    border-color: #f36b3d;
}

.PricingAddOnSection .PricingCard .ant-checkbox-checked::after {
    border: 1px solid #f36b3d;
}

.PricingAddOnSection .PricingCard .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.PricingAddOnSection .PricingCard .ant-checkbox:hover .ant-checkbox-inner,
.PricingAddOnSection .PricingCard .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #f36b3d;
}

.PricingAddOnSection .PricingCard .ant-checkbox {
    position: absolute;
    top: 15px;
    right: 30px;
}

/* First Step Style End */
/* Second Step Style Start */
.StepAppointmentInfo {
    width: 100%;
    float: left;
    padding: 50px 0;
}

.StepAppointmentInfo .InspectionStepTitle {
    padding: 5px 0;
}

.StepAppointmentInfo .CouponCodeTotalSection {
    box-shadow: none;
    padding: 0;
}

.StepAppointmentInfo .CouponCodeTotalAmount {
    font-size: 25px;
    margin-left: 8px;
}

.StepsFormCardContainer {
    width: 100%;
    float: left;
}

.StepsFormCardContainer {
    width: 100%;
    float: left;
    padding: 30px 0;
    padding-top: 50px;
}

.StepsFormCard {
    width: 100%;
    float: left;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    padding: 50px 15px;
    border-radius: 10px;
    padding-bottom: 60px;
}

.StepsFormCard .ant-form-horizontal .ant-form-item-control {
    flex:1 !important;
    min-width: 100% !important;
}

.StepsFormCard .ant-form-item {
    margin-bottom: 0;
}

.StepsFormCard .ant-form-item .ant-form-item-label {
    width: 100%;
    float: left;
}

.StepsFormCard .ant-form-item .ant-form-item-label label {
    width: 100%;
    float: left;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.StepsFormCard .ant-form-item .ant-picker {
    width: 100%;
    float: left;
    border: solid 2px #ebebeb;
    border-radius: 25px;
    padding: 8px 15px;
}

.StepsFormCard .ant-form-item .ant-picker .ant-picker-suffix {
    font-size: 20px;
    color: #101010;
}

.StepsFormCard .ant-form-item .ant-picker .ant-picker-input>input {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.StepsFormCard .ant-form-item .ant-form-item-label>label::after {
    display: none;
}

/* Second Step Style End */
/* Third Step Style Start */
.StepSellerDetailsStepsContainer {
    width: 100%;
    float: left;
}

.StepSellerDetailsStepsContainer .StepsFormCard .ant-form-item {
    margin-bottom: 24px;
}

.StepSellerDetailsStepsContainer .StepsFormCard .ant-form-item .ant-input,
.StepSellerDetailsStepsContainer .StepsFormCard .ant-form-item .ant-input-number {
    width: 100%;
    float: left;
    font-size: 16px;
    border: solid 2px #ebebeb;
    border-radius: 25px;
    padding: 8px 15px;
    font-weight: 600;
}
.StepSellerDetailsStepsContainer .StepsFormCard .ant-form-item .ant-input-number {
    padding: 5px 15px;
}
.StepSellerDetailsStepsContainer .StepsFormCard .ant-form-item .ant-input-number .ant-input-number-input{
    padding: 0;
}

.StepSellerDetailsStepsContainer .StepsFormCard .ant-form-item hr {
    margin-left: -15px;
    margin-right: -15px;
    border-color: #b4b4b4;
    border-width: 2px;
}

.SellerDetailsRadioGroup .ant-radio-wrapper {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.SellerDetailsRadioGroup .ant-radio-checked .ant-radio-inner {
    border-color: #ebebeb;
}

.SellerDetailsRadioGroup .ant-radio-inner::after {
    transform: scale(1);
    opacity: 1;
    background-color: #ebebeb;
}

.SellerDetailsRadioGroup .ant-radio-checked .ant-radio-inner::after {
    background-color: #f36a2b;
}

.SellerDetailsRadioGroup .ant-radio-wrapper:hover .ant-radio,
.SellerDetailsRadioGroup .ant-radio:hover .ant-radio-inner,
.SellerDetailsRadioGroup .ant-radio-input:focus+.ant-radio-inner {
    border-color: #f36a2b;
}

.SellerDetailsRadioGroup .ant-radio-checked::after {
    border: 1px solid #f36a2b;
}

.AcceptTermsCheckBoxSet {
    width: 100%;
    float: left;
    display: flex;
}

.AcceptTermsCheckBoxSet:first-child {
    margin-bottom: 15px;
}

.AcceptTermsCheckBoxSet .ant-checkbox-wrapper {
    margin-right: 10px;
}

.AcceptTermsCheckBoxSet .AcceptTermsCheckBoxText {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.AcceptTermsCheckBoxSet .AcceptTermsCheckBoxText a {
    color: #000;
    font-weight: 600;
    text-decoration: underline;
}

.AcceptTermsCheckBoxSet .ant-checkbox-checked::after {
    border: 1px solid #f36a2b;
}

.AcceptTermsCheckBoxSet .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36a2b !important;
    border-color: #f36a2b !important;
}

.AcceptTermsCheckBoxSet .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.AcceptTermsCheckBoxSet .ant-checkbox:hover .ant-checkbox-inner,
.AcceptTermsCheckBoxSet .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #f36a2b;
}

.StepSellerDetailsStepsContainer .StepsFormCard {
    padding: 15px 15px;
    padding-bottom: 30px;
}

/* Third Step Style End */
/* Payment Tab Start */
.SafeBuyPaymentLogo {
    position: absolute;
    width: 110px;
    height: 110px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -66px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
}

.SafeBuyPaymentModal .SafeBuyPaymentLogo img {
    width: 70px;
}

.SafeBuyPaymentModal .ant-modal-content {
    border-radius: 5px;
}

.SafeBuyPaymentModal .ant-modal-body {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0;
    flex-direction: column;
    overflow: hidden;
    border-radius: 5px;
}

.SafeBuyPaymentHeader {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #5b5b65;
    background-color: #e8e9eb;
    text-align: center;
    padding: 30px 15px;
    padding-top: 60px;
}

.SafeBuyPaymentHeader .SafeBuyPaymentHeaderTitle {
    font-size: 30px;
    color: #000;
    font-weight: 600;
}

.SafeBuyPaymentModal .ant-modal-body .SafeBuyPaymentBody {
    width: 100%;
    float: left;
    padding: 50px 50px;
    background-color: #f5f5f7;
}

.SafeBuyPaymentModal .SafeBuyPaymentInput {
    width: 100%;
    float: left;
}

.SafeBuyPaymentModal .SafeBuyPaymentInputSet {
    width: 100%;
    float: left;
    display: flex;
}

.SafeBuyPaymentModal .SafeBuyPaymentInputSet:first-child .ant-input-affix-wrapper {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.SafeBuyPaymentModal .SafeBuyPaymentInputSet:last-child .ant-input-affix-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.SafeBuyPaymentModal .SafeBuyPaymentInputSet:last-child .ant-input-affix-wrapper:first-child {
    border-right: none;
    border-bottom-right-radius: 0;
}
.SafeBuyPaymentModal .SafeBuyPaymentInputSet:last-child .ant-input-affix-wrapper:first-child {
    border-bottom-left-radius: 0;
}
.SafeBuyPaymentModal .SafeBuyPaymentCheckBox {
	width: 100%;
    float: left;
    padding: 10px 15px;
    border: solid 1px #d9d9d9;
    margin: 15px 0;
}
.SafeBuyPaymentInput {
	width: 100%;
	float: left;
}
.SafeBuyPaymentInput .ant-btn {
	    width: 100%;
    float: left;
    font-size: 21px;
    color: #fff;
    background-color: #41ade6;
    height: auto;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 5px;
}
/* Payment Tab End */

.BannerInputContainer .autocomplete-dropdown-container {
    position: absolute;
    background-color: #fff;
    z-index: 4;
    border-radius: 25px;
    padding: 5px 0;
    border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 9px 10px rgba(0, 0, 0, 0.23);
  overflow: hidden;
}
.BannerInputContainer .autocomplete-dropdown-container div[role=option]{
        width: 100%;
    float: left;
    padding: 5px 12px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    transition: all ease .4s;
    cursor: pointer;
}
.BannerInputContainer .autocomplete-dropdown-container div[role=option]:hover{
       background-color: #f36a2b;
    color: #fff;
    transition: all ease .4s;
}
.BannerInputContainer .location-search-input {
    transition: all ease 0s;
}
.BannerInputContainer .location-search-input:hover,
.BannerInputContainer .location-search-input:active,
.BannerInputContainer .location-search-input:focus {
    border-color: #fff !important;
    box-shadow: none;
}
.BannerInputContainer .location-search-input[aria-expanded="true"]{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all ease 0s;
}
.TimeRadioGroup .ant-radio-button-wrapper {
    margin: 5px 10px;
    margin-left: 0;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #010101 !important;
    border-radius: 25px !important;
}

.TimeRadioGroup .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

.TimeRadioGroup .ant-radio-button-wrapper:hover {
    position: relative;
    color: #f36b3d !important;
}

.TimeRadioGroup .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: #fff !important;
    background: #f36b3d !important;
    border-color: #f36b3d !important;
}
.PaymentStepsContainer {
    width: 100%;
    float: left;
    padding: 50px 15px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PaymentStepsContainer .form-label {
    width: 100%;
    float: left;
    font-size: 14px;
}
.PaymentStepsContainer .form-value {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 600;
}
.PaymentStepsContainer .form-group {
    margin-bottom: 1rem;
    width: 100%;
    float: left;
}
.PaymentStepsContainer .form-group {
    margin-bottom: 25px;
}
.PaymentStepsContainer .StepsFormCard {
    padding: 20px 15px;
    padding-bottom: 20px;
}
.Stripe-container {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
}
.PaymentSuccessBlock {
    width: 100%;
    float: left;
    padding: 20px 15px;
}
.steps-action.confirm-booking {
    justify-content: center ;
    border-top: transparent;
}
.location-error-message {
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    margin-top: 5px;
    display: flex;
}
.CouponCodeTotalSection.box-shadow-none {
 box-shadow: none;
}
.example {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  .address {
    color: #f36a2b !important;
  }
  .ant-card-wrapper {
    background-image: url('../.././assets/img/PopularNew.png');
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: 10px 10px;
  }
  .BannerInputContainer .location-search-input:disabled{
      color: #7f7f8b;
  }
  .ant-card-wrapper-car-history{
    background-image: url('../.././assets/img/mostneeded.png');
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: 10px 10px;
  }

  .slotAvaliableMsg{
    color: rgb(236, 69, 69);
  }