.PageHeaderContainer {
	width: 100%;
	float: left;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
        padding: 10px 0;
}
.HeaderLogo img {
	height: 50px;
}
.HeaderInnerContainer {
	width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 13px;
}
.ContactInfoMenuIcon {
margin-right: 5px;
}
.HeaderContactInfo .ContactInfoMenu:last-child {
	margin-left: 20px;
}