@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
body{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
.txt-brown {
  color: #f36a2b !important;
}
.buttonRipple {
      border-radius: 25px;
      overflow: hidden;
}
/* Media Query Start */
@media (max-width: 764px) {
.HomePageContainer .PricingCard {
  margin-bottom: 15px;
}
.PricingCardInfo .PricingCardAmount {
    font-size: 25px;
    margin-bottom: 0;
}
.PricingCardInfo .PricingCardPlan {
    font-size: 14px;
}
.InspectionStepTitle {
    font-size: 15px;
    padding: 20px 8px;
}
.PricingAddOnTitle {
    font-size: 25px;
}
.PricingAddOnSubTitle {
    font-size: 17px;
}
.BannerTextTitle {
    font-size: 28px;
    line-height: 34px;
}
.FooterContainer .d-flex.justify-content-between {
  flex-direction: column;
}
.FooterContainer .d-flex.justify-content-between span:first-child{
  margin-bottom: 10px;
}
.PricingAddOnSection .PricingCardSection {
    padding: 15px 0;
}
.StepAppointmentInfo {
    padding: 10px 0;
}
.StepsFormCardContainer {
    padding: 20px 0;
    padding-top: 20px;
}
.HeaderContactInfo{
  display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 10px;
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.ant-steps.StepsItems .ant-steps-item:after {
    left: -40px;
    width: 60px;
    margin-left: 130px;
}

.ant-steps.StepsItems .ant-steps-item:last-child:after {
    display: none;
}
.HeaderLogo img {
    height: 35px;
}
.BannerSection:after {
  content: "";
  position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.77);
}
/* 16/11//2024 Edited */

.ant-steps.StepsItems {
  overflow: hidden;
  display: flex;
  overflow-x: scroll;
  flex-direction: row;
}
.ant-steps.StepsItems .ant-steps-item {
  flex: none;
  position: relative;
  white-space: nowrap;
  padding-left: 21px;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: none !important;
}
}

/* Media Query End */